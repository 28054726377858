import { properCase } from "src/utils/utility";
import { AttendanceDateData } from "../AttendanceTypes";
import { DropdownOptionsType } from "src/types";

export function filterAbsentDates(data: AttendanceDateData[]): string[] {
  return data.reduce((absentDates: string[], item: AttendanceDateData) => {
    if (item.attendance === "ABSENT") {
      absentDates.push(item.onlyDate);
    }
    return absentDates;
  }, []);
}

export const audienceTypeOptions = [
  { value: 0, label: "student" },
  { value: 1, label: "class" },
];

export const durationTypeOptions = [
  { value: 0, label: "Weekly" },
  { value: 1, label: "Monthly" },
  { value: 2, label: "Daily" },
];

export function generateSortOptions(
  sortOptions: DropdownOptionsType[],
  t: (s: string) => string
): DropdownOptionsType[] {
  const result: DropdownOptionsType[] = [];

  sortOptions.forEach((option) => {
    ["ASCENDING", "DESCENDING"].forEach((order) => {
      result.push({
        label: `${t(option.label)} (${properCase(order)})`,
        value: JSON.stringify({ [option.value]: order }),
      });
    });
  });

  return result;
}
